// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.qcRCmMGm3KTu6bvtujD1{display:block}.qcRCmMGm3KTu6bvtujD1.GVePLFPzi28u86vNqH2t .closeMachineButton{width:200px;height:56px;max-width:100%}@media(min-width: 0){.qcRCmMGm3KTu6bvtujD1.GVePLFPzi28u86vNqH2t .closeMachineButton{margin:48px 0}}@media(min-width: 400px){.qcRCmMGm3KTu6bvtujD1.GVePLFPzi28u86vNqH2t .closeMachineButton{margin:48px 0}}@media(min-width: 768px){.qcRCmMGm3KTu6bvtujD1.GVePLFPzi28u86vNqH2t .closeMachineButton{margin:56px 0}}.qcRCmMGm3KTu6bvtujD1.GVePLFPzi28u86vNqH2t .closeMachineButton:hover{color:rgb(242, 242, 240) !important;background-color:#434b4f}.qcRCmMGm3KTu6bvtujD1.GVePLFPzi28u86vNqH2t .closeMachineButton:hover .selectMachineIcon:after{width:24px;height:24px;display:inline-block;background-repeat:no-repeat;content:"";background-image:url("../assets/icons/Next-cloud.svg")}`, ""]);
// Exports
export var wrapper = `qcRCmMGm3KTu6bvtujD1`;
export var explodedViewPage = `GVePLFPzi28u86vNqH2t`;
export default ___CSS_LOADER_EXPORT___;
